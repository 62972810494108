import React from "react";
import "nprogress/nprogress.css";
import { LocaleProvider } from "@insightfulscience/smart-react/i18";
import "../styles/override-nprogress.css";
import AssetsBaseUrlProvider from "@src/AssetsBaseUrlProvider";
import "@src/next-router-events";
import { initSentry } from "@src/sentry";
import resources from "../locales";
import "../src/utils/midDateFormatter"; // TODO: remove this import after migration to @is/i18
import "../src/routing-utils";

initSentry();

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, err }) => (
	<LocaleProvider resources={resources}>
		<AssetsBaseUrlProvider>
			<Component {...pageProps} err={err} />
		</AssetsBaseUrlProvider>
	</LocaleProvider>
);

export default App;
