const routes = {
	purchase: {
		base: "/purchase",
		configSubscription: "/purchase/configure-subscription",
		billing: "/purchase/billing",
		placeOrder: "/purchase/payment",
		invoice: "/purchase/invoice",
		confirmRenewal: "/purchase/confirm-renewal",
	},
	auth: {
		login: "/auth/login",
		signUp: "/auth/signup",
		logout: "/auth/sign-out",
		exchange: "/auth/exchange",
		forgotPassword: "/auth/forgot-password",
		forgotPasswordSuccess: "/auth/forgot-password/success",
		resetPassword: "/auth/reset-password",
		resetPasswordSuccess: "/auth/reset-password/success",
		resetPasswordFail: "/auth/reset-password/fail",
	},
	myAccount: "/account",
	myAccountPreferences: "/account/preferences",
	account: {
		entitlements: {
			index: "/account/entitlements",
			activations: "/account/entitlements/activations",
		},
	},
	subscription: {
		dashboard: "/account/subscription",
		activations: "/account/subscription/activations",
		renew: "/account/subscription/renew",
		settings: "/account/subscription/settings",
		billing: {
			index: "/account/subscription/billing",
			changePaymentMethod: "/account/subscription/billing/changepayment",
			changePlan: "/account/subscription/billing/changeplan",
			activations: "/account/subscription/billing/activations",
		},
		entitlements: {
			index: "/account/subscription/entitlements",
			activations: "/account/subscription/entitlements/activations",
		},
		sso: "/account/subscription/sso",
		scim: "/account/subscription/scim",
		domains: "/account/subscription/domains",
		billingInfoUpdate: "/account/subscription/billinginfo-update",
		invoice: "/account/subscription/invoice",
		purchaseActivations: "/account/subscription/purchase-activations",
		cancelSurvey: "/account/subscription/cancel-survey",
		cancelConfirm: "/account/subscription/cancel-confirm",
		upgradeTrialPlan: "/account/subscription/upgrade-trial-plan",
		upgradeTrialSuccess: "/account/subscription/upgrade-trial-success",
		upgradeTrialPayment: "/account/subscription/upgrade-trial-payment",
	},
	invoice: {
		pay: "/invoice/pay",
		success: "/invoice/pay-success",
		invalid: "/invoice/invalid",
	},
	freeTrial: "/free-trial",
	howToBuy: "/pricing",
	getStartedBiologics: "/get-started-biologics",
	confirmEmail: "/free-trial/confirm-email",
	completeProfile: "/free-trial/complete-profile",
	exchangeTrial: "/api/free-trial/exchange",
	quoteSuccess: "/quote/success",
	requestLicenseKeySuccess: "/biorad/success",
	freeTrialBiologics: {
		index: "free-trial-biologics",
		success: "/free-trial-biologics/success",
	},
	courseLicenses: "/free-course-license",
	igem: "/igem",
	features: {
		index: "/features",
	},
	contact: {
		index: "/contact",
		success: "/contact/success",
	},
	biopharma: "/biopharma",
	pricing: "/pricing",
	pricingBiologics: "/pricing-biologics",
	quote: "/quote",
	security: "/security",
	legal: "/legal",
	privacyPolicy: "/privacy-policy",
	academy: "/academy",
	errors: {
		403: "/403",
		404: "/404",
		default: "/error",
	},
	header: {
		products: {
			prime: {
				index: "/features/prime",
				sequenceAnalysis: "/features/prime#sequence-analysis",
				cloning: "/features/prime#molecular-biology",
				automation: "/features/prime#data-management",
				allFeatures: "/features/prime#all-feature",
			},
			biologics: {
				index: "/features/biologics",
				antibodyDiscovery: "/features/biologics#antibody-discovery",
				candidate: "/features/biologics",
				security: "/features/biologics#security",
				allFeatures: "/features/biologics",
			},
		},
		resources: {
			primeResources: {
				index: "/academy",
				gettingStarted: "/series/getting-started",
				academy: "/academy",
				userManual: "https://manual.geneious.com/en/latest/",
				knowledgeBase: "https://help.geneious.com/hc/en-us/categories/360003482792",
				download: "/updates",
				installPlugins: "/plugins",
			},
			biologicsResources: {
				index: "https://help.geneiousbiologics.com/hc/en-us",
				gettingStarted: "https://help.geneiousbiologics.com/hc/en-us/articles/360045069411",
				academy: "/academy#antibody-discovery",
				userGuide: "https://help.geneiousbiologics.com/hc/en-us/categories/360003073291",
				dataSecurity: "/security",
				requestDemo: "/free-trial-biologics",
			},
		},
		support: {
			gnPrimeSupport: {
				index: "https://help.geneious.com/",
				helpCenter: "https://help.geneious.com/",
				contactSupport: "https://help.geneious.com/hc/en-us/requests/new",
			},
			gnBiologicsSupport: {
				index: "https://help.geneiousbiologics.com/",
				helpCenter: "https://help.geneiousbiologics.com/",
				contactSupport: "https://help.geneiousbiologics.com/hc/en-us/requests/new",
			},
		},
		pricing: "/pricing",
		signIn: {
			index: "/account",
			biologics: "https://biologics.geneious.com/",
			prime: "https://annotator.geneious.com/	",
		},
		freeTrial: "/free-trial",
	},
	footer: {
		prime: {
			index: "/features/prime",
			features: {
				index: "/features/prime",
				sangerSequencing: "/features/sanger-sequence-analysis",
				ngsSequencing: "/features/ngs-visualization-downstream-analysis",
				assemblyMapping: "/features/assembly-mapping",
				alignments: "/features/sequence-alignment",
				phylogenetics: "/features/phylogenetic-tree-building",
				molecularCloning: "/features/molecular-cloning",
				primerDesign: "/features/primer-design",
				crispr: "/features/crispr",
				annotatePredict: "/features/sequence-analysis-annotation-prediction",
				microsatelliteAnalysis: "/features/microsatellite-genotyping",
				ncbiBlast: "/features/searching-blast-genbank",
				workflows: "/features/workflows",
				convertFileFormats: "/features/import-export-sequence-data",
				geneiousCloud: "/features/cloud",
			},
			resources: {
				index: "/academy",
				freeTrial: "/free-trial",
				pricing: "/pricing",
				quoteRequest: "/quote",
				download: "/updates",
				plugins: "/plugins",
				apiCli: "/api-developers",
				academy: "/academy",
				userManual: "https://manual.geneious.com/en/latest/",
				courseLicenses: "/free-course-license",
				igem: "/igem",
				whatsNew: "/updates",
				support: "https://help.geneious.com/hc/en-us",
			},
		},
		biologics: {
			index: "/features/biologics",
			features: {
				index: "/features/biologics",
				antibodyDiscovery: "/features/biologics",
				candidateSelection: "/features/biologics",
				clusteringFiltering: "/features/clustering-filtering",
				ngsSequencing: "/features/antibody-ngs-data-analysis",
				dataSecurity: "/security",
				freeAntibodyAnnotator: "/features/antibody-sequence-analysis",
			},
			resources: {
				index: "https://help.geneiousbiologics.com/",
				freeTrial: "/get-started-biologics",
				pricing: "/pricing-biologics",
				academy: "/academy#antibody-discovery",
				userGuide: "https://help.geneiousbiologics.com/hc/en-us/categories/360003073291",
				support: "https://help.geneiousbiologics.com/",
				whatsNew: "/updates",
				contactSales: "/quote",
			},
		},
		company: {
			aboutGeneious: "/about",
			customers: "/customers",
			citations: "/citations",
			dotmatics: "https://www.dotmatics.com/",
			portfolio: "https://www.dotmatics.com/portfolio",
			careers: "https://www.dotmatics.com/about-careers",
			security: "/security",
			contact: "/contact",
		},
		privacyPolicy: "/privacy-policy",
		legal: "/legal",
		doNotSell: "https://donotsell.dotmatics.com/hc/en-us/requests/new",
		twitter: "https://www.twitter.com/geneious",
		youtube: "https://www.youtube.com/c/geneious",
		linkedin: "https://www.linkedin.com/company/geneious",
	},
	// FIXME: most of those are not external links anymore, should be refactored
	external: {
		index: "/",
		howToBuy: "/pricing",
		support: "//support.geneious.com/",
		termsOfService: "/legal",
		privacyPolicy: "/privacy-policy",
		contactUs: "/contact",
		prime: "/features/prime",
		biopharma: "/features/biologics",
		customers: "/customers",
		solutions: "/",
		resources: "/academy",
		tutorials: "/academy",
		plugins: "/plugins",
		whatsNew: "/updates",
		crispr: "/features/crispr",
		freeTrial: "/free-trial",
		download: "/download",
		quote: "/quote",
		about: "/about",
		leadership: "/about",
		news: "/about",
		careers: "https://www.dotmatics.com/about-careers",
		licenseAgreement: "//assets.geneious.com/legal/eula.html",
		manual: "https://manual.geneious.com/en/latest/",
		coronavirus:
			"https://help.geneious.com/hc/en-us/articles/360045070991-Assembly-of-SARS-CoV-2-genomes-from-tiled-amplicon-Illumina-sequencing-using-Geneious-Prime",
		help: "https://help.geneious.com/hc/en-us",
		courseLicenses: "/free-course-license",
		igem: "/igem",
		dotmatics: "https://www.dotmatics.com/",
		portfolio: "https://www.dotmatics.com/portfolio",
		platform: "https://www.dotmatics.com/platform",
		careersExternal: "https://www.dotmatics.com/about-careers",
		citations: "/citations",
		features: "/features",
		cloud: "/features/cloud",
		security: "/security",
		phylogeneticTreeBuilding: "/features/phylogenetic-tree-building",
		academy: "/academy",
		machineAccessTokenActivation: "https://help.geneious.com/hc/en-us/articles/23705323148308",
		nonFloatingLicenseInfoUrl:
			"https://help.geneious.com/hc/en-us/articles/23681334617748-Licensing-limits",
		doNotSell: "https://donotsell.dotmatics.com/hc/en-us/requests/new",
		requestDemo: "/free-trial-biologics",
		externalSignUp: "https://annotator.geneious.com/sign-up",
		gpLink: "https://policies.google.com/privacy",
		gtLink: "https://policies.google.com/terms",
		products: {
			prism: "//www.graphpad.com/",
			geneious: "//www.geneious.com/",
			snapgene: "//www.snapgene.com/",
			labArchives: "//www.labarchives.com/",
			proteinMetrics: "//proteinmetrics.com/",
			omiq: "//www.omiq.ai/",
			fcsExpress: "//denovosoftware.com/",
			nQuery: "//www.statsols.com/",
		},
		sequenceAnalysis: "/features/sequence-analysis-annotation-prediction",
		molecularBiology: "/features/prime#molecular-biology",
		automation: "/features/prime#data-management",
		biopharmaDiscovery: "/features/biologics#antibody-discovery",
		biopharmaClustering: "/features/clustering-filtering",
		biopharmaSecurity: "/security",
		academyGettingStarted: "/series/getting-started",
		userManual: "https://manual.geneious.com/en/latest/",
		academyAntibodyDiscovery: "/academy/#antibody-discovery",
		biopharmaDataSecurity: "/security",
		biopharmaRequestDemo: "/biologics-trial",

		externalGetStartedBiologics: "/get-started-biologics",
		knowledgeBase: "https://help.geneious.com/hc/en-us/categories/360003482792",
		biopharmaResourcesGS: "https://help.geneiousbiologics.com/hc/en-us/articles/360045069411",
		biopharmaUserGuide: "https://help.geneiousbiologics.com/hc/en-us/categories/360003073291",
		primeHelp: "https://help.geneious.com/",
		primeContactSupport: "https://help.geneious.com/hc/en-us/requests/new",
		biologicsHelp: "https://help.geneiousbiologics.com/",
		biologicsContactSupport: "https://help.geneiousbiologics.com/hc/en-us/requests/new",
		biologics: "https://biologics.geneious.com",
		biologicsStarter: "https://annotator.geneious.com",
		ssoGuide: "https://www.geneious.com/support/faq/sso-guide/",
		scimGuide: "https://www.geneious.com/support/faq/scim-guide/",
		domainsGuide: "https://www.geneious.com/support/faq/domains-guide/",
	},
	requestSponsorshipSuccess: "/igem/success",
	featuresAntibody: "/features/antibody-sequence-analysis",
};

export default routes;
